<app-loading-container [loading]="appUiSandbox.moveLoading$ | async">
    <app-card [ngSwitch]="!!suggestion">
        <div class="u-text-align-center u-margin-bottom" *ngIf="provider">
            <img [src]="'assets/images/suppliers/' + provider.toString().toLowerCase() + '-small.png'" class="h-[100px]"
                 [alt]="provider"/>
        </div>

        <div *ngSwitchCase="true">
            <div class="u-margin-bottom-triple">
                <h5 class="u-text-align-center">
                    {{ suggestion?.productNameLabels | translatedLabel | async }}
                </h5>
                <p *ngIf="suggestion?.descriptionLabels" class="u-text-align-center">
                    {{ suggestion?.descriptionLabels | translatedLabel | async }}
                </p>
            </div>

            <div class="__content-container">
                <div class="__content-left">
                    <ng-container [ngTemplateOutlet]="adviceLeft"
                                  *ngIf="suggestion.advice; else suggestionLeft"></ng-container>
                </div>

                <div class="__content-right u-flex-column">
                    <ng-container [ngTemplateOutlet]="adviceRight"
                                  *ngIf="suggestion.advice; else suggestionRight"></ng-container>
                </div>
            </div>
        </div>

        <ng-template #adviceRight>
            <div class="u-margin-bottom-triple"
                 [innerHTML]="suggestion.adviceUspLabels | translatedLabel | async"></div>
            <app-button (click)="select.emit(suggestion)"
                        theme="secondary">{{ 'TELECOM.SUGGESTION.ADVICE.CTA' | translate }}
            </app-button>
        </ng-template>

        <ng-template #suggestionRight>
            <div class="u-margin-bottom-triple">
                <h6>{{ 'TELECOM.SUGGESTION.PRICE.TITLE' | translate }}</h6>
                <table>
                    <tr *ngIf="suggestion?.installationLabels">
                        <td class="u-color-muted u-text-align-right">
                            {{ 'TELECOM.SUGGESTION.PRICE.INSTALLATION' | translate }}
                        </td>
                        <td class="u-color-muted u-padding-left">
                            {{ suggestion?.installationLabels | translatedLabel | async }}
                        </td>
                    </tr>
                    <tr *ngIf="suggestion?.activationLabels">
                        <td class="u-color-muted u-text-align-right">
                            {{ 'TELECOM.SUGGESTION.PRICE.ACTIVATION' | translate }}
                        </td>
                        <td class="u-color-muted u-padding-left">
                            {{ suggestion?.activationLabels | translatedLabel | async }}
                        </td>
                    </tr>
                    <tr>
                        <td class="u-color-muted u-text-align-right">
                            <span>{{ 'TELECOM.SUGGESTION.PRICE.PRICE' | translate }}</span>
                        </td>
                        <td class="u-padding-left">
                            <div
                                [ngClass]="{
                                'u-flex-row u-flex-align-items-center': hasDiscount(),
                                'u-flex-column': hasNoDiscount()
                            }"
                            >
                            <span
                                class="u-font-weight-bold"
                                [ngClass]="{
                                    'u-font-size-large u-color-accent': hasNoDiscount(),
                                    ' u-margin-right-half u-text-line-through u-font-size-medium': hasDiscount()
                                }"
                            >{{ suggestion?.price | price }}</span
                            >
                                <span
                                    class="u-color-muted u-font-size-small"> {{ 'TELECOM.SUGGESTION.PRICE.PER_MONTH' | translate }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="hasDiscount()">
                        <td class="u-color-muted u-text-align-right">
                            <span>{{ 'TELECOM.SUGGESTION.PRICE.DISCOUNT' | translate }}</span>
                        </td>
                        <td class="u-padding-left">
                            <div class="u-flex-column">
                                <span
                                    class="u-font-size-large u-color-accent u-font-weight-bold">{{ suggestion?.discount | price }}</span>
                                <span *ngIf="suggestion?.discountTerm1Labels" class="u-color-muted u-font-size-small">{{
                                        suggestion?.discountTerm1Labels | translatedLabel | async
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <app-button (click)="select.emit(suggestion)" theme="secondary">{{ 'CHOOSE' | translate }}</app-button>

            <p *ngIf="provider === telenet"
               class="u-margin-top-double u-font-size-small u-color-muted u-font-style-italic">
                * {{ 'TELECOM.TELENET.MICONCEPT.INFO' | translate }}
            </p>
        </ng-template>

        <ng-template #adviceLeft>
            <div [innerHTML]="suggestion.adviceDescriptionLabels | translatedLabel | async"></div>
        </ng-template>

        <ng-template #suggestionLeft>
            <ng-container *ngIf="moveSandbox.telecomBundle$ | async as telecomBundle">
                <div *ngIf="telecomBundle.internet">
                    <h6>{{ 'TELECOM.SUGGESTION.INTERNET.TITLE' | translate }}</h6>
                    <table class="u-margin-bottom">
                        <tr *ngIf="suggestion?.wifiSpeedLabelLabels">
                            <td class="u-color-muted u-text-align-right">{{ 'TELECOM.SUGGESTION.INTERNET.SPEED' | translate }}</td>
                            <td class="u-padding-left">
                                {{ suggestion?.wifiSpeedLabelLabels | translatedLabel | async }}
                            </td>
                        </tr>
                        <tr *ngIf="suggestion?.volumeLabelLabels">
                            <td class="u-color-muted u-text-align-right">{{ 'TELECOM.SUGGESTION.INTERNET.VOLUME' | translate }}</td>
                            <td class="u-padding-left">
                                {{ suggestion?.volumeLabelLabels | translatedLabel | async }}
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="telecomBundle.mobilePhone">
                    <h6>{{ 'TELECOM.SUGGESTION.MOBILE.TITLE' | translate }}</h6>
                    <table class="u-margin-bottom">
                        <tr *ngIf="suggestion?.mobileMinutesLabelLabels">
                            <td class="u-color-muted u-text-align-right">
                                {{ 'TELECOM.SUGGESTION.MOBILE.MINUTES.TITLE' | translate }}
                            </td>
                            <td class="u-padding-left">
                                {{ suggestion?.mobileMinutesLabelLabels | translatedLabel | async }}
                            </td>
                        </tr>
                        <tr *ngIf="suggestion?.mobileDataDescriptionLabels">
                            <td class="u-color-muted u-text-align-right">{{ 'TELECOM.SUGGESTION.MOBILE.DATA' | translate }}</td>
                            <td class="u-padding-left">
                                {{ suggestion?.mobileDataDescriptionLabels | translatedLabel | async }}
                            </td>
                        </tr>
                        <tr *ngIf="suggestion?.mobileSms">
                            <td class="u-color-muted u-text-align-right">{{ 'TELECOM.SUGGESTION.MOBILE.SMS' | translate }}</td>
                            <td class="u-padding-left">{{ suggestion?.mobileSms }}</td>
                        </tr>
                        <tr *ngIf="suggestion?.mobileDownloadLabel">
                            <td class="u-color-muted u-text-align-right">{{ 'TELECOM.SUGGESTION.MOBILE.DATA' | translate }}</td>
                            <td class="u-padding-left">{{ suggestion?.mobileDownloadLabel }}</td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="telecomBundle.digitalTelevision" class="u-margin-bottom">
                    <h6>{{ 'TELECOM.SUGGESTION.TV.TITLE' | translate }}</h6>
                    <div>
                        <span>{{ suggestion?.digitalTelevisionLabelLabels | translatedLabel | async }}</span>
                    </div>
                </div>
                <div *ngIf="telecomBundle.fixedLine">
                    <h6>{{ 'TELECOM.SUGGESTION.FIXED_LINE.TITLE' | translate }}</h6>
                    <div>
                        <span>{{ suggestion?.fixedLineLabelLabels | translatedLabel | async }}</span>
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <div *ngSwitchCase="false">
            <div
                class="__not-found u-color-muted u-text-align-center u-margin-0-auto"
                [innerHTML]="'TELECOM.SUGGESTION.NOT_FOUND' | translate: { provider: provider }"
            ></div>
        </div>
    </app-card>
</app-loading-container>
