import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepComponent } from '@app/form/components/base-step/base-step.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { providerOptions } from '@app/telecom/constants/telecom.constants';
import { TelecomOffer } from '@app/wizard/telecom/interfaces/telecom-offer';
import { Provider } from '@app/wizard/telecom/telecom.interfaces';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';

@Component({
    selector: 'app-provider',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-loading-container [loading]="appUiSandbox.moveLoading$ | async">
                    <app-card class="u-flex-column u-flex-align-items-start">
                        <h3>{{ 'TELECOM.PROVIDER.TITLE' | translate }}</h3>
                        <p class="u-color-muted u-margin-bottom-triple">
                            {{
                                'STEP_INDICATION'
                                    | translate
                                        : {
                                              start: 2,
                                              end: 2
                                          }
                            }}
                        </p>

                        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
                            <app-select-input
                                [options]="providerOptions"
                                formControlName="provider"
                                [hasMargin]="false"
                                [hasMarginDouble]="true"
                                [label]="'TELECOM.PROVIDER.LABEL' | translate"
                            ></app-select-input>

                            <div
                                *ngIf="form.get('provider').value === provider.Telenet"
                                class="u-flex-responsive u-flex-align-items-center u-margin-bottom-double u-margin-top-double"
                            >
                                <img
                                    src="assets/images/suppliers/miconcept-black.png"
                                    class="u-margin-right-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape h-[60px]"
                                />
                                <p class="u-font-size-small u-font-style-italic u-color-muted">
                                    {{ 'TELECOM.TELENET.MICONCEPT.INFO' | translate }}
                                </p>
                            </div>

                            <app-previous-submit (previous)="goToPrevious()"></app-previous-submit>
                        </form>
                    </app-card>
                </app-loading-container>
            </div>
        </app-card-container>
    `,
})
export class ProviderContainer extends BaseStepComponent implements OnInit, OnDestroy {
    public form = this.formBuilder.group({
        provider: [null, [Validators.required]],
    });
    public provider = Provider;
    public providerOptions = providerOptions;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        protected readonly moveSandbox: MoveSandbox,
        private readonly route: ActivatedRoute,
        public readonly appUiSandbox: AppUiSandbox
    ) {
        super(moveSandbox);
    }

    public ngOnInit(): void {
        this.moveSandbox.providerChoiceOnce$.subscribe((providerChoice) => {
            this.providerFormControl().patchValue(providerChoice);
        });
    }

    public goToPrevious(): void {
        this.persistData(() => {
            this.router
                .navigate(['../telecom-bundle'], {
                    relativeTo: this.route,
                })
                .then();
        });
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.persistData(() => {
                this.router
                    .navigate(['../../suggestions'], {
                        relativeTo: this.route,
                    })
                    .then();
            });
        }
    }

    public ngOnDestroy(): void {}

    private persistData(callback: () => void): void {
        const payload: TelecomOffer = {
            providerChoice: this.providerFormControl().value,
        };
        this.persist('telecomOffer', payload, callback);
    }

    private providerFormControl(): AbstractControl {
        return this.form.get('provider');
    }
}
